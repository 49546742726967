import Swiper from 'swiper';
import 'swiper/css';
import { FreeMode, Autoplay, Navigation, Pagination } from 'swiper/modules';

const swiper = () => {
    let swiperInstances = [];
    const swipers = document.querySelectorAll('.swiper');

    swipers.forEach(swiper => {
        const slug = swiper.dataset.swiperSlug;

        let options = {};
        switch (slug) {
            case 'projectsA':
                options = {
                    modules: [Navigation],
                    spaceBetween: 24,
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                    },
                    autoplay: null,
                };
                break;

            case 'projectsB':
                options = {
                    modules: [Navigation],
                    spaceBetween: 24,
                    slidesPerView: 2.25,
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                    },
                    autoplay: null,
                };
                break;

            case 'projects-description':
                options = {
                    slidesPerView: "1",
                    autoHeight: true,
                    direction: "vertical",
                };
                break;

            case 'team':
                options = {
                    modules: [Navigation],
                    slidesPerView: "auto",
                    spaceBetween: 24,
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                    }
                };
                break;

            case 'logos':
                options = {
                    modules: [FreeMode, Autoplay],
                    loop: true,
                    freeMode: {
                        enabled: true,
                        momentum: true,
                    },
                    autoplay: {
                      delay: 1,
                      disableOnInteraction: false
                    },
                    slidesPerView: "6",
                    spaceBetween: 24,
                    speed: 4000,
                };
                break;

            case 'testimonials':
                options = {
                    modules: [Pagination],
                    slidesPerView: "auto",
                    spaceBetween: 24,
                    pagination: {
                      el: ".swiper-pagination",
                      type: "progressbar",
                    },
                };
                break;
        }

        swiperInstances.push(new Swiper(swiper, options));

        switch (slug) {
            case 'projectsA':
                const swiperInstance = swiperInstances.find(instance => instance.el === swiper);
                // get next element
                const $caption = swiper.nextElementSibling;
                const $captionWrapper = $caption.querySelector('.swiper-captionWrapper');
                const $captionItems = $caption.querySelectorAll('.swiper-captionItem');

                setTimeout(() => {
                    adjustCaptionHeight($caption, $captionWrapper, $captionItems, swiperInstance);
                }, 500);

                swiperInstance.on('slideChange', function () {
                    adjustCaptionHeight($caption, $captionWrapper, $captionItems, swiperInstance);
                });

                break;
        }
    });

    function adjustCaptionHeight($caption, $captionWrapper, $captionItems, swiperInstance) {
        const $captionItem = $captionItems[swiperInstance.realIndex];

        // $caption gets the height of the current captionItem
        const captionHeight = $captionItem.offsetHeight;
        $caption.style.height = `${captionHeight}px`;
        // alert(captionHeight);

        // move $captionWrapper to the current captionItem
        const captionItemTop = $captionItem.offsetTop;
        $captionWrapper.style.top = `-${captionItemTop}px`;
    }
}

export default swiper;